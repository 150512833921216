@import 'fonts/fonts';
@import 'skin/mixins';
@import 'skin/variables';

body.login {
    position: relative;
    background-color: $white;
}

.login h1 a{
    max-width: 100%;
    margin-bottom: 30px;
    z-index: 10;
    position: relative;
    outline: none!important;
    box-shadow: none!important;
    background: url( 'images/login-logo.png' );
    width: 288px;
    height: 97px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.login div#login {
    width: 420px;
    max-width: 100%;
    padding-top: 10%!important;
}

form#loginform {
    padding: 28px 24px 50px;
    background-color: $white;
    border: 1px solid rgba(0,0,0,0.05);
    box-shadow: 8px 8px 20px rgba(0,0,0,0.25);
    margin-top: 0;
    border-top: 4px solid $red;
}

.login label, .login form .forgetmenot label {
    color: $blue;
    font-weight: normal;
    text-transform: uppercase;;
    font-size: 15px;
    font-family: $p-sb;
}

.login input[type="text"], 
.login input[type="password"] {
    background-color: $white;
    color: $black;
    padding: 8px;
    font-size: 16px;
    font-family: $p;
    border: 2px solid $red;
    margin: 0 auto 20px auto;    
    box-shadow: none;
}
.login input{
    outline: none!important;
}
.wp-core-ui .button-group.button-large .button, .wp-core-ui .button.button-large {
    background-color: $blue;
    position: absolute;
    display: block;
    font-size: 20px;
    font-family: $p-sb;
    line-height: 26px!important;
    min-height: 0!important;
    padding: 7px 28px 7px 15px !important;
    border: 0px;
    border-radius: 0px;
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: none!important;
    outline: none!important;
    @include letter-spacing(100);
    width: 200px;
    max-width: 80%;
    height: 50px;
    cursor: pointer;
    margin: 46px auto;
    left: 0;
    right: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    letter-spacing: 0px;
}

.wp-core-ui .button-group.button-large .button:hover, .wp-core-ui .button.button-large:hover {
    background-color: $white;
    color: $blue;
}

.wp-core-ui .button-primary {
    outline: 0 !important;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    background: none;
}

p#nav {
  display: none;
}

p#backtoblog {
  display: none;
}

#login form p.submit {
    text-align: center;
}

.login #login_error, .login .message {
    border-left: 0;
    text-align: center;
    background-color: transparent;
    color: inherit;
    font-family: $p;
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 320px;
    box-shadow: none; 
    color: $blue;   
}

input[type=text]:focus, input[type=search]:focus, input[type=radio]:focus, input[type=tel]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, input[type=password]:focus, input[type=checkbox]:focus, input[type=color]:focus, input[type=date]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, select:focus, textarea:focus {
    box-shadow: none;
}

.wp-core-ui .button-primary.active, .wp-core-ui .button-primary.active:focus, .wp-core-ui .button-primary.active:hover, .wp-core-ui .button-primary:active {
    box-shadow: none;
}

.wp-core-ui .button-primary.focus, .wp-core-ui .button-primary:focus {
    box-shadow: none;
}
input[type=checkbox]:focus{
    border-color: $blue;
}
input[type=checkbox]:checked:before{
    color: $blue;
}