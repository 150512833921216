@font-face {
    font-family: 'PoppinsRegular';
    src: url('css/fonts/PoppinsRegular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsMedium';
    src: url('css/fonts/PoppinsMedium.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsItalic';
    src: url('css/fonts/PoppinsItalic.otf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('css/fonts/PoppinsSemiBold.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PoppinsBold';
    src: url('css/fonts/PoppinsBold.otf');
    font-weight: normal;
    font-style: normal;
}